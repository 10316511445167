import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { ROUTE_PATH } from "./route.constant";

import PageNotFound from "../pages/PageNotFound/PageNotFound";
import AppLayout from "../layout/AppLayout";
import AuthLayout from "../layout/AuthLayout";
import MainLayout from "../layout/MainLayout";
import WelcomeAuth from "../pages/WelcomeAuth/index";
import ProjectContentDetail from "../pages/Home/ProjectCotent/Myproject";
import CampaignDetail from "../pages/Home/ProjectCotent/Myproject/CampaignDetail";
const HomePage = React.lazy(() => import("../pages/Home/HomePages/HomePages"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTE_PATH.WELCOME,
            element: (
              <Suspense fallback={null}>
                <WelcomeAuth />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: ROUTE_PATH.HOMEPAGE,
            element: (
              <Suspense fallback={null}>
                <HomePage />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.PROJECTCONTENTDETAIL,
            element: (
              <Suspense fallback={null}>
                <ProjectContentDetail />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.CAMPAIGNCONTENT,
            element: (
              <Suspense fallback={null}>
                <CampaignDetail />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);
