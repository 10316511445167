//Invite account
export const apiInviteAccount = "/api/invite";
export const apiProject = "/api/project";
export const apiCampaign = "/api/campaign";
export const apiQuiz = "/api/quiz";
export const apiScoreCampaign = "/api/campaign/score";
export const apiCreateQuiz = "/api/user/quiz";
export const apiProfile = "/api/profile";
export const apiClaimDaily = "/api/claim/daily";
export const apiQuizDaily = "/api/claim/quiz";
export const apiGetRanking = "/api/campaign/rank";
export const apiGetToken = "/api/user/profile/balancesERC20";
