import {
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  Avatar,
  DatePicker,
  Select,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import CampaignServices from "../../../../../services/CampaignServices";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
function ModalCreateCampaign(props) {
  const { createCampaign, setCreateCampaign, idProject, onOk } = props;
  const [form] = Form.useForm();
  dayjs.extend(utc);
  const [listToken, setListToken] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const startTimeUTC = dayjs(values.startTime)
          .utc()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format("YYYY-MM-DDTHH:mm:ss");
        const endTimeUTC = dayjs(values.endTime)
          .utc()
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .format("YYYY-MM-DDTHH:mm:ss");
        try {
          const bodyData = {
            name: values.name?.trim(),
            index: 0,
            network: values.network,
            description: values.description?.trim(),
            token: values.token.toString(),
            totalReward: values.totalReward.toString(),
            maxParticipants: values.maxParticipants.toString(),
            startTime: startTimeUTC,
            endTime: endTimeUTC,
            symbolToken: selectedSymbol,
          };

          const body = {
            projectId: idProject,
          };
          const res = await CampaignServices.createCampaign(bodyData, body);

          if (res.success === true) {
            toast.success("Add campaign successfully");
            // setCreateCampaign(false);
            form.resetFields();
            onOk();
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const getListToken = (body) => {
    CampaignServices.getListTokenCampaign(body)
      .then((res) => {
        if (res) {
          setListToken(res?.data);
        }
      })
      .catch((er) => {
        toast.error(er.message);
      });
  };

  const handleNetworkChange = (value) => {
    form.resetFields(["token"]);
    setSelectedSymbol(null); // Reset symbol khi network thay đổi
    if (value) {
      const searchParams = { network: value };
      getListToken(searchParams);
    }
  };

  const handleTokenChangeGetSymbol = (value) => {
    const selectedToken = listToken.find(
      (item) => item.contractAddress === value
    );
    setSelectedSymbol(selectedToken?.symbol || null);
  };
  return (
    <Modal
      className="modal_quizz_campaign"
      title="Create Campaign"
      visible={createCampaign}
      onCancel={() => setCreateCampaign(false)}
      footer={[
        <div className="flex justify-center align-center mt-5 btn-claimBonus gap-[15px]">
          <Button
            key="cancel"
            type="primary"
            onClick={() => setCreateCampaign(false)}
          >
            Cancel
          </Button>
          ,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Create
          </Button>
          ,
        </div>,
      ]}
      bodyStyle={{
        maxHeight: "60vh", // Giới hạn chiều cao nội dung
        overflowY: "auto", // Thêm cuộn dọc nếu nội dung dài
        padding: "16px", // Khoảng cách bên trong nội dung
      }}
    >
      <Form form={form} layout="vertical" initialValues={{ badge: 1 }}>
        <Form.Item
          label="Campaign Name"
          name="name"
          rules={[
            { required: true, message: "Please input the campaign name!" },
          ]}
        >
          <Input placeholder="Enter campaign name (e.g., Catia)" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please input the description!" }]}
        >
          <Input placeholder="Enter description" />
        </Form.Item>
        <Form.Item
          label="Network"
          name="network"
          rules={[{ required: true, message: "Please choose the network!" }]}
        >
          <Select
            options={[
              { value: "kaia", label: "Kaia" },
              { value: "ethereum", label: "Ethereum" },
              { value: "polygon", label: "Polygon" },
              { value: "bsc", label: "Bsc" },
              { value: "arbitrum", label: "Arbitrum" },
              { value: "base", label: "Base" },
            ]}
            placeholder="Choose a network"
            onChange={(value) => {
              handleNetworkChange(value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Start Date"
          name="startTime"
          rules={[{ required: true, message: "Please input the start date!" }]}
        >
          <DatePicker
            placeholder="Select start date"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endTime"
          rules={[{ required: true, message: "Please input the end date!" }]}
        >
          <DatePicker placeholder="Select end date" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Token"
          name="token"
          rules={[{ required: true, message: "Please input the token!" }]}
        >
          <Select
            options={
              listToken.length > 0
                ? listToken?.map((item) => ({
                    value: item?.contractAddress,
                    symbol: item?.symbol,
                    label: (
                      <Row>
                        <Col span={12}>Name: {item?.name}</Col>
                        <Col span={12} className="text-right">
                          Balance : {item?.balance}
                        </Col>
                      </Row>
                    ),
                  }))
                : []
            }
            onChange={handleTokenChangeGetSymbol}
            placeholder="Choose a token"
          />
        </Form.Item>
        <Form.Item
          label="Total Reward"
          name="totalReward"
          rules={[
            { required: true, message: "Please input the total reward!" },
          ]}
        >
          <InputNumber
            min={1}
            style={{ width: "100%" }}
            placeholder="Enter total reward (e.g., 1)"
          />
        </Form.Item>
        <Form.Item
          label="Max Participants"
          name="maxParticipants"
          rules={[
            { required: true, message: "Please input the max participants!" },
          ]}
        >
          <InputNumber
            min={1}
            style={{ width: "100%" }}
            placeholder="Enter max participants (e.g., 1)"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalCreateCampaign;
