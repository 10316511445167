import React from "react";
import { Avatar, Button } from "antd";
import { ROUTE_PATH } from "../../routes/route.constant";
import { useNavigate } from "react-router-dom";
import { InforUserContainer } from "./styled";

const InforUser = ({ onToggle }) => {
  const dataUser = JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  const handleSubmit = () => {
    onToggle();
    navigate(ROUTE_PATH.HOMEPAGE);
  };
  return (
    <InforUserContainer
      style={{
        margin: "0 auto",
        padding: "20px",
        textAlign: "center",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
        backgroundColor: "rgba(255, 121, 102, 0.4)",
      }}
    >
      {/* Header Section */}
      <div style={{ marginBottom: "20px" }}>
        <Avatar
          size={100}
          src={dataUser?.avatarUrl}
          style={{ marginBottom: "10px", border: "2px solid #1A73E8" }}
        />
        <div
          style={{
            fontWeight: "bold",
            color: "#fff",
            fontSize: "18px",
            marginBottom: "10px",
          }}
        >
          {dataUser.username}
        </div>
        <div style={{ color: "#fff", marginBottom: "10px" }}>
          LineID: {dataUser.lineId}
        </div>
        <div style={{ color: "#fff", marginBottom: "10px" }}>
          Address Wallet: {dataUser.address}
        </div>

        <Button
          type="primary"
          className="buttonContinue"
          onClick={handleSubmit}
        >
          Get Started
        </Button>
      </div>
    </InforUserContainer>
  );
};

export default InforUser;
