import queryString from "query-string";
import http from ".";
import { apiCreateQuiz, apiQuiz } from "./apiRouter";

const getListQuizz = (body) => {
  const params = queryString.stringify(body);
  return http.get(`${apiQuiz}/list?${params}`);
};

const createQuiz = (bodyData, body) => {
  const params = queryString.stringify(body);
  return http.post(`${apiCreateQuiz}?${params}`, bodyData);
};

const getQuizzDaily = () => {
  return http.get(`${apiQuiz}/random-question`);
};

const QuizzServices = {
  getListQuizz,
  createQuiz,
  getQuizzDaily
};

export default QuizzServices;
