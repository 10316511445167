import { Modal } from "antd";
import React from "react";
import congratulations from "../../../../../../assets/imgs/Congratulations.gif";

function ModalCongratulation(props) {
  const {
    modalResult,
    setModalResult,
    congratulationData,
    timeStartQuiz,
    timeEndQuiz,
  } = props;

  return (
    <Modal
      className="modal_quizz_campaign"
      visible={modalResult}
      onCancel={() => setModalResult(false)}
      footer={null}
      centered
    >
      <div className="p-6  rounded-lg text-center">
        <h1 className="text-2xl font-extrabold text-green-500 mb-4">
          🎉 Congratulations! 🎉
        </h1>
        <img
          className="w-[40%] mx-auto mb-1 rounded-lg"
          src={congratulations}
          alt="Congratulations"
        />
        <div className=" p-4 rounded-lg shadow-md">
          <p className="text-lg font-medium">
            <span className="text-[#000]">Start Time:</span>{" "}
            <span className="text-[#FF33FF]">{timeStartQuiz}</span>
          </p>
          <p className="text-lg font-medium">
            <span className="text-[#000]">End Time:</span>{" "}
            <span className="text-[#FF33FF]">{timeEndQuiz}</span>
          </p>
          <p className="text-lg font-medium">
            <span className="text-[#000]">Correct Answers:</span>{" "}
            <span className="text-[#FF33FF]">
              {congratulationData?.correct}/{congratulationData?.totalQuestion}
            </span>
          </p>
          <p className="text-lg font-medium">
            <span className="text-[#000]">Score:</span>{" "}
            <span className="text-[#FF33FF]">
              {congratulationData?.correct}
            </span>
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default ModalCongratulation;
