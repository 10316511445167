import React, { useState } from "react";
import "./index.css";
import Welcome from "./welcome";
import CheckWallet from "./checkWallet";
import InforUser from "./inforUser";
const WelcomeAuth = () => {
  const [isLogin, setIsLogin] = useState(0);
  const [dataAuth, setDataAuth] = useState({});

  const onToggle = (data) => {
    setIsLogin(isLogin + 1);
    setDataAuth(data);
  };
  const onToggle1 = () => {
    setIsLogin(isLogin - 1);
  };
  const onToggle2 = () => {
    setIsLogin(0);
  };

  return (
    <div className="container">
      {isLogin === 0 && <Welcome onToggle={onToggle} />}
      {isLogin === 1 && (
        <CheckWallet
          onToggle={onToggle}
          onBack={onToggle1}
          dataAuth={dataAuth}
        />
      )}
      {isLogin === 2 && <InforUser onToggle={onToggle} onBack={onToggle2} />}
    </div>
  );
};

export default WelcomeAuth;
