import queryString from "query-string";
import http from ".";
import { apiCampaign, apiGetToken } from "./apiRouter";

const getListCampaign = (body) => {
  const params = queryString.stringify(body);
  return http.get(`${apiCampaign}/list?${params}`);
};
const createCampaign = (bodyData, body) => {
  const params = queryString.stringify(body);
  return http.post(`${apiCampaign}/add?${params}`, bodyData);
};

const getListTokenCampaign = (body) => {
  const params = queryString.stringify(body);
  return http.get(`${apiGetToken}?${params}`);
};

const CampaignServices = {
  getListCampaign,
  createCampaign,
  getListTokenCampaign,
};

export default CampaignServices;
