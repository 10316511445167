import { Modal, Button, Typography } from "antd";
import React, { useState } from "react";
import TotalScoreQuizCampaignServices from "../../../../../../services/apiScoreServices";
import { getCurrentLocalDate } from "../../../../../../constants/init.constants";

const { Text } = Typography;

function ModalQuizzCampaign(props) {
  const {
    showQuizz,
    setShowQuizz,
    dataQuestion,
    idQuiz,
    handleQuizCompletion,
    setClickTimeEnd,
    onOk,
  } = props;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [submittedQuestions, setSubmittedQuestions] = useState({});

  const currentQuestion = dataQuestion[currentQuestionIndex];

  const handleAnswerChange = (answer) => {
    if (!submittedQuestions[currentQuestion?.id]) {
      setSelectedAnswers((prev) => ({
        ...prev,
        [currentQuestion?.id]: answer,
      }));
    }
  };

  const handleSubmit = () => {
    setSubmittedQuestions((prev) => ({
      ...prev,
      [currentQuestion?.id]: true,
    }));
  };

  const handleNext = () => {
    if (currentQuestionIndex < dataQuestion.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleFinalSubmit();
    }
  };

  const handleFinalSubmit = () => {
    const correctCount = dataQuestion.reduce((count, question) => {
      if (selectedAnswers[question?.id] === question.correctAnswer) {
        return count + 1;
      }
      return count;
    }, 0);

    console.log(
      `You answered ${correctCount} out of ${dataQuestion.length} dataQuestion correctly!`
    );

    const bodyParam = { score: correctCount };
    const body = { quizId: idQuiz };

    TotalScoreQuizCampaignServices.totalScoreQuizzCampaign(
      bodyParam,
      body
    ).then((res) => {
      if (res.message === "Success") {
        setShowQuizz(false);
        const timeOfClickEnd = getCurrentLocalDate();
        setClickTimeEnd(timeOfClickEnd);
        handleQuizCompletion({
          correct: correctCount,
          totalQuestion: dataQuestion.length,
        });
        onOk();
      }
    });
  };

  return (
    <Modal
      className="modal_quizz_campaign"
      visible={showQuizz}
      onCancel={() => setShowQuizz(false)}
      footer={[
        <div
          className="flex justify-center align-center mt-5 gap-[2rem] "
          key="footer"
        >
          <div
            className={` ${
              !selectedAnswers[currentQuestion?.id] ||
              submittedQuestions[currentQuestion?.id]
                ? "btnDisable"
                : "btn-claimBonus"
            }`}
          >
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={
                !selectedAnswers[currentQuestion?.id] ||
                submittedQuestions[currentQuestion?.id]
              }
            >
              Submit
            </Button>
          </div>
          <div
            className={` ${
              !submittedQuestions[currentQuestion?.id]
                ? "btnDisable"
                : "btn-claimBonus"
            }`}
          >
            <Button
              type="primary"
              onClick={handleNext}
              disabled={!submittedQuestions[currentQuestion?.id]}
            >
              {currentQuestionIndex < dataQuestion.length - 1 ? (
                <div className="flex gap-2">
                  <span>Next</span>
                </div>
              ) : (
                <div className="flex gap-2">
                  <span>Finish</span>
                </div>
              )}
            </Button>
          </div>
        </div>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "red",
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          {`${currentQuestionIndex + 1}/${dataQuestion.length}`}
        </div>

        <Text
          strong
          style={{ fontSize: "16px", textAlign: "center", color: "#49b8e4" }}
        >
          {currentQuestion?.text}
        </Text>

        <div style={{ width: "100%" }}>
          {currentQuestion?.options?.map((option, index) => {
            let backgroundColor = "#fff";
            let textColor = "#000";
            let borderColor = "#d9d9d9";

            if (submittedQuestions[currentQuestion?.id]) {
              if (option === currentQuestion.correctAnswer) {
                backgroundColor = "rgb(125, 235, 187, 0.7)";
                textColor = "#155724";
                borderColor = "green";
              } else if (
                selectedAnswers[currentQuestion?.id] === option &&
                option !== currentQuestion.correctAnswer
              ) {
                backgroundColor = "rgb(255, 200, 201, 0.7)";
                textColor = "#721c24";
                borderColor = "red";
              }
            } else if (selectedAnswers[currentQuestion?.id] === option) {
              backgroundColor = "rgb(220, 230, 255)";
              borderColor = "#4eb6ce";
            }

            return (
              <div
                key={index}
                onClick={() => handleAnswerChange(option)}
                style={{
                  padding: "10px",
                  margin: "8px 0",
                  border: `2px solid ${borderColor}`,
                  borderRadius: "16px",
                  cursor: submittedQuestions[currentQuestion?.id]
                    ? "not-allowed"
                    : "pointer",
                  backgroundColor,
                  color: textColor,
                  boxShadow: "0 6px 0 #4eb6ce",
                  transition: "all 0.3s ease",
                  textAlign: "center",
                  fontWeight: 600,
                  marginBottom: "1rem",
                }}
              >
                {option}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

export default ModalQuizzCampaign;
