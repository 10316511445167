import axios from "axios";
import { toast } from "sonner";
import { getAccessToken } from "../../src/constants/utils";
const token = getAccessToken();
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: token ? `${token.accessToken}` : "",
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

http.interceptors.request.use(
  async (config) => {
    const token = getAccessToken() ?? "";
    if (token.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `${token.accessToken}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => {
    if (response.data) {
      return response.data;
    }
  },
  (error) => {
    // return toast.error(error.response.data.message);
    return toast.error(error.response.data.data.fields.data.message);
  }
);

export default http;
