// import liff from "@line/liff";

import liff from "@line/liff";

const initializeLiff = async () => {
  try {
    await liff.init({ liffId: process.env.REACT_APP_LIFF_TOKEN_ID });
    if (liff.isLoggedIn()) {
      const token = liff.getAccessToken();
      return { token };
    } else {
      liff.login();
    }
  } catch (error) {
    console.error("Error initializing LIFF:", error);
    throw error;
  }
};

export default initializeLiff;
