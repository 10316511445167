import { Empty, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./rankCampaign.css";
import rank1 from "../../../../../../assets/svgs/1.svg";
import rank2 from "../../../../../../assets/svgs/2.svg";
import rank3 from "../../../../../../assets/svgs/3.svg";
import rank4 from "../../../../../../assets/svgs/4.svg";
import rank5 from "../../../../../../assets/svgs/5.svg";
import rank6 from "../../../../../../assets/svgs/6.svg";
import { toast } from "sonner";
import ProjectServices from "../../../../../../services/ProjectServices";
import { formatAddress } from "../../../../../../constants/utils";

function ModalRankCampaign(props) {
  const { modalRankingCampaign, setModalRankingCampaign, idCampaign } = props;
  const [listDataUserTop10, setListDataUserTop10] = useState([]);
  const getListProject = (body) => {
    ProjectServices.getListRankingTop10(body)
      .then((res) => {
        setListDataUserTop10(res?.data);
      })
      .catch((er) => {
        toast.error(er.message);
      });
  };
  useEffect(() => {
    const params = {
      campaignId: idCampaign,
      limit: 10,
    };
    getListProject(params);
  }, [idCampaign]);

  const rankImages = [rank1, rank2, rank3, rank4, rank5, rank6];

  return (
    <Modal
      className="modal_quizz_campaign"
      visible={modalRankingCampaign}
      title="Ranking Campaign"
      onCancel={() => setModalRankingCampaign(false)}
      footer={null}
      bodyStyle={{
        maxHeight: "60vh", // Giới hạn chiều cao nội dung
        overflowY: "auto", // Thêm cuộn dọc nếu nội dung dài
        padding: "16px", // Khoảng cách bên trong nội dung
      }}
    >
      <div className="ranking-container">
        {listDataUserTop10?.map((item) => (
          <div key={item.rank} className={`ranking-box  rank-${item.rank}`}>
            {item.rank <= 6 && (
              <img
                className="rank-number"
                src={rankImages[item.rank - 1]}
                alt={`Rank ${item.rank}`}
                style={{
                  width: item.rank === 1 ? "50px" : "35px",
                }}
              />
            )}
            <div className="rank-content">
              <div className="mr-[25px]">
                <strong className="text-[20px] text-[#000]">
                  {item?.user?.username}
                </strong>
                <p className="text-[16px]  text-[#000]">
                  Address: {formatAddress(item?.user?.address)}
                </p>
              </div>
              <p className="text-[20px] text-[#000]">
                Score: {item.totalScore}
              </p>
            </div>
          </div>
        ))}
        {listDataUserTop10?.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ margin: "20px" }}
            description={<span style={{ color: "#000" }}>No data</span>}
          />
        )}
      </div>
    </Modal>
  );
}

export default ModalRankCampaign;
