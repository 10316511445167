import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import initializeLiff from "../../services/liff";
import { ROUTE_PATH } from "../../routes/route.constant";
import { WelcomeContainer } from "./styled";
import logo from "../../assets/imgs/logo.svg";
import btnLine from "../../assets/imgs/btb-lognLine.svg";
import btnMetamask from "../../assets/imgs/btn-loginMetask.svg";

const Welcome = ({ onToggle }) => {
  const navigate = useNavigate();

  const login = async () => {
    try {
      const { token } = await initializeLiff();
      if (!token) return console.error("Missing LINE token");

      localStorage.setItem("lineToken", token);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login/line`,
        { lineToken: token },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        const user = response.data.data.detail;
        localStorage.setItem("accessToken", JSON.stringify(response.data.data));
        localStorage.setItem("userData", JSON.stringify(user));
        user?.isFirstLogin ? onToggle(user) : navigate(ROUTE_PATH.HOMEPAGE);
      } else {
        console.error("API request failed:", response.status);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <WelcomeContainer>
      <img className="w-[70%] h-[100%] mb-[2rem]" src={logo} alt="Logo" />
      <span
        className="text-center mb-[16rem]"
        style={{ fontSize: 24, color: "#000" }}
      >
        Build your profile and explore the endless Web3 rewards space
      </span>
      <div className="flex flex-col items-center">
        <img
          className="w-[70%] h-[100%]"
          src={btnMetamask}
          alt="Login with Metamask"
        />
        <span className="mt-[1rem] mb-[1rem]">Or</span>
        <img
          className="w-[70%] h-[100%] mb-[2rem]"
          src={btnLine}
          alt="Login with LINE"
          onClick={login}
        />
      </div>
    </WelcomeContainer>
  );
};

export default Welcome;
