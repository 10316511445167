import styled from "styled-components";

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -10rem;
  .buttonContinue {
    background: linear-gradient(0deg, #ff7966, #ff7966),
      radial-gradient(
        100% 100% at 50% 100%,
        rgba(255, 255, 255, 0) 43.25%,
        rgba(255, 127, 55, 0.5) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border: 1px solid;

    border-image-source: linear-gradient(
      153.43deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 83.33%
    );
    box-shadow: 0px 8px 25px 0px rgba(255, 121, 102, 0.5);
    height: 35px;
    width: 150px;
    border-radius: 20px;
  }
`;

export const CheckWalletContainer = styled.div`
  .buttonContinue {
    background: linear-gradient(0deg, #ff7966, #ff7966),
      radial-gradient(
        100% 100% at 50% 100%,
        rgba(255, 255, 255, 0) 43.25%,
        rgba(255, 127, 55, 0.5) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border: 1px solid;

    border-image-source: linear-gradient(
      153.43deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 83.33%
    );
    box-shadow: 0px 8px 25px 0px rgba(255, 121, 102, 0.5);
    height: 35px;
    width: 150px;
    border-radius: 20px;
  }
  .checkGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 95%;
    max-width: 600px;
    margin-bottom: 23rem;
  }
`;

export const InforUserContainer = styled.div`
  .buttonContinue {
    background: linear-gradient(0deg, #ff7966, #ff7966),
      radial-gradient(
        100% 100% at 50% 100%,
        rgba(255, 255, 255, 0) 43.25%,
        rgba(255, 127, 55, 0.5) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border: 1px solid;

    border-image-source: linear-gradient(
      153.43deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 83.33%
    );
    box-shadow: 0px 8px 25px 0px rgba(255, 121, 102, 0.5);
    height: 35px;
    width: 150px;
    border-radius: 20px;
  }
`;
