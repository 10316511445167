import queryString from "query-string";
import http from ".";
import { apiGetRanking, apiProject } from "./apiRouter";

const getListProject = (body) => {
  const params = queryString.stringify(body);
  return http.get(`${apiProject}/list?${params}`);
};
const createProject = (bodyData) => {
  return http.post(`${apiProject}`, bodyData);
};
const getListRankingTop10 = (body) => {
  const params = queryString.stringify(body);
  return http.get(`${apiGetRanking}?${params}`);
};

const ProjectServices = {
  getListProject,
  createProject,
  getListRankingTop10
};

export default ProjectServices;
