import React, { Suspense } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const AuthLayout = () => {
  const navigate = useNavigate();
  //   const isLogin = localStorage.getItem(ACCESS_TOKEN_KEY);

  //   useEffect(() => {
  //     if (isLogin) return navigate(ROUTE_PATH.USER_MANAGEMENT);
  //   });

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};

export default AuthLayout;
