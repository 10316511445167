import { Modal, Button, Form, Input, Avatar, Space, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import QuizzServices from "../../../../../../services/QuizzServices";
import { toast } from "sonner";

function ModalCreateQuizz(props) {
  const { createQuizz, setCreateQuizz, idCampaign } = props;
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        // Xử lý gom các đáp án vào mảng
        const processedQuestions = values.questions.map((question) => {
          // Chuyển đổi answers từ các key riêng lẻ về dạng mảng
          const answers = [
            question["answers[0]"],
            question["answers[1]"],
            question["answers[2]"],
            question["answers[3]"],
          ];

          // Xóa các key answers[0], answers[1],... để tránh dữ liệu dư thừa
          delete question["answers[0]"];
          delete question["answers[1]"];
          delete question["answers[2]"];
          delete question["answers[3]"];

          return {
            ...question,
            options: answers, // Thêm mảng answers vào câu hỏi
          };
        });

        const finalData = {
          ...values,
          questions: processedQuestions, // Gán lại questions đã xử lý
        };

        try {
          const bodyData = {
            // finalData,
            network: finalData.network,
            name: finalData.name,
            description: finalData.description,
            questions: finalData.questions,
          };

          const body = {
            campaignId: idCampaign,
          };
          const res = await QuizzServices.createQuiz(bodyData, body);

          if (res.success === true) {
            toast.success("Add quiz successfully");
            form.resetFields();
            // onOk();
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const optionsNetwork = [
    { value: "kaia", label: "kaia" },
    { value: "ethereum", label: "ethereum" },
    { value: "polygon", label: "polygon" },
    { value: "bsc", label: "bsc" },
    { value: "arbitrum", label: "arbitrum" },
    { value: "base", label: "base" },
  ];

  return (
    <Modal
      title="Create Quizz"
      className="modal_quizz_campaign"
      visible={createQuizz}
      onCancel={() => setCreateQuizz(false)}
      footer={[
        <div className="flex justify-center align-center mt-5 btn-claimBonus gap-[15px]">
          <Button
            key="cancel"
            type="primary"
            onClick={() => setCreateQuizz(false)}
          >
            Cancel
          </Button>
          ,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Create
          </Button>
          ,
        </div>,
      ]}
      bodyStyle={{
        maxHeight: "60vh", // Giới hạn chiều cao nội dung
        overflowY: "auto", // Thêm cuộn dọc nếu nội dung dài
        padding: "16px", // Khoảng cách bên trong nội dung
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          badge: 1,
          questions: [
            {
              correctAnswer: null,
            },
          ],
        }}
      >
        {/* Quizz Title */}
        <Form.Item
          label="Quizz Name"
          name="name"
          rules={[{ required: true, message: "Please input the quizz title!" }]}
        >
          <Input placeholder="Enter quizz title (e.g., Catia Basics)" />
        </Form.Item>

        <Form.Item
          label="Quizz Description"
          name="description"
          rules={[
            { required: true, message: "Please input the quizz description!" },
          ]}
        >
          <Input placeholder="Enter quizz description (e.g., Catia Basics)" />
        </Form.Item>

        <Form.Item
          label="Network"
          name="network"
          rules={[{ required: true, message: "Please select the network!" }]}
        >
          <Select placeholder="Select a network">
            {optionsNetwork.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Questions */}
        <Form.List name="questions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  direction="vertical"
                  style={{ display: "flex", marginBottom: 8 }}
                >
                  {/* Question */}
                  <Form.Item
                    {...restField}
                    label="Text"
                    name={[name, "text"]}
                    fieldKey={[fieldKey, "text"]}
                    rules={[
                      { required: true, message: "Please input the question!" },
                    ]}
                  >
                    <Input placeholder="Enter the question" />
                  </Form.Item>

                  {/* Answers */}
                  {[0, 1, 2, 3].map((index) => (
                    <Form.Item
                      key={index}
                      {...restField}
                      label={`Answer ${index + 1}`}
                      name={[name, `answers[${index}]`]}
                      fieldKey={[fieldKey, `answers[${index}]`]}
                      rules={[
                        {
                          required: true,
                          message: `Please input answer ${index + 1}!`,
                        },
                      ]}
                    >
                      <Input placeholder={`Enter answer ${index + 1}`} />
                    </Form.Item>
                  ))}

                  {/* Correct Answer */}
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => {
                      // Lấy danh sách các câu trả lời từ Form
                      const answers = [
                        getFieldValue(["questions", name, "answers[0]"]),
                        getFieldValue(["questions", name, "answers[1]"]),
                        getFieldValue(["questions", name, "answers[2]"]),
                        getFieldValue(["questions", name, "answers[3]"]),
                      ];

                      // Lọc các đáp án không rỗng
                      const validAnswers = answers.filter((answer) => !!answer);

                      return (
                        <Form.Item
                          {...restField}
                          label="Correct Answer"
                          name={[name, "correctAnswer"]}
                          fieldKey={[fieldKey, "correctAnswer"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select the correct answer!",
                            },
                          ]}
                        >
                          <Select placeholder="Select the correct answer">
                            {validAnswers.map((answer, index) => (
                              <Select.Option key={index} value={answer}>
                                {answer || `Answer ${index + 1}`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>

                  {/* Remove Question */}
                  <Button
                    styles={{
                      background: "#49b8e4",
                    }}
                    type="primary"
                    danger
                    onClick={() => remove(name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Question
                  </Button>
                </Space>
              ))}

              {/* Add Question */}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  block
                >
                  Add Question
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default ModalCreateQuizz;
