import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BadgePlus, CircleArrowLeft, Crown } from "lucide-react";
import { Button } from "antd";
import {
  DEFAULT_PAGE_SIZE,
  formatDate,
  getCurrentLocalDate,
} from "../../../../../constants/init.constants";
import QuizzServices from "../../../../../services/QuizzServices";
import { ROUTE_PATH } from "../../../../../routes/route.constant";
import ModalQuizzCampaign from "./modalQuizz";
import ModalCreateQuizz from "./createQuizz";
import ModalRankCampaign from "./modalRankCampaign";
import { toast } from "sonner";
import ModalCongratulation from "./modalQuizz/modalCongratulations";
import linkLogo from "../../../../../assets/imgs/Symbol.png";
function CampaignDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const dataCampaign = location.state;
  const getProfileAddress = JSON.parse(localStorage.getItem("userDataProfile"));

  const [showQuizz, setShowQuizz] = useState(false);
  const [createQuizz, setCreateQuizz] = useState(false);
  const [modalRankingCampaign, setModalRankingCampaign] = useState(false);

  const [listDataQuizz, setListDataQuizz] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [modalResult, setModalResult] = useState(false);
  const getListDataQuizz = (body) => {
    QuizzServices.getListQuizz(body)
      .then((res) => {
        if (res.data === undefined) {
          localStorage.clear();
          navigate(ROUTE_PATH.WELCOME);
        }
        setListDataQuizz(res.data);
      })
      .catch((er) => {
        // toast.error(er.message);
        localStorage.clear();
        navigate(ROUTE_PATH.WELCOME);
        // setHasMore(false); // Ngừng tải khi gặp lỗi
      });
  };

  useEffect(() => {
    const Params = {
      campaignId: dataCampaign.id,
      limit: DEFAULT_PAGE_SIZE,
      page: 1,
    };
    getListDataQuizz(Params);
  }, [dataCampaign]);

  const handleBack = () => {
    navigate(-1);
  };
  const [congratulationData, setCongratulationData] = useState({
    correct: null,
    totalQuestion: null,
  });
  const handleQuizCompletion = (resultData) => {
    setCongratulationData(resultData);
    setModalResult(true);
  };
  const [clickTimeStart, setClickTimeStart] = useState(null);
  const [clickTimeEnd, setClickTimeEnd] = useState(null);

  const handleStartQuizzClick = (item) => {
    const timeOfClick = getCurrentLocalDate();
    setClickTimeStart(timeOfClick);
    setShowQuizz(true);
    setDataQuestion(item);
  };
  return (
    <div className="min-h-screen bg_common">
      <header className="flex justify-between px-4 py-4  flex-col">
        <div>
          <button
            className="text-[#000] font-bold text-lg flex items-center space-x-2"
            onClick={handleBack}
          >
            <CircleArrowLeft /> <span>Back</span>
          </button>
        </div>
      </header>

      {/* Campaign Info Section */}
      <div className="flex justify-center items-center p-4">
        <div className="flex items-center justify-center w-full  rounded-lg p-4  Bg_Card_Comomon  ">
          <div className="flex flex-col items-center  space-y-4">
            <div>
              <img
                src={dataCampaign?.logo || linkLogo}
                alt="Campaign Logo"
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  border: "2px solid white",
                }}
              />
            </div>

            <div className="flex-1 text-center">
              <h2 className="text-[24px] font-bold ">{dataCampaign?.name}</h2>
              {dataCampaign?.creator === getProfileAddress?.address && (
                <div className="text-[16px]">
                  Ticket: {getProfileAddress?.profile?.quizTickets}🎫
                </div>
              )}
              <p className="text-[16px] text-[#FF33FF] ">
                {dataCampaign?.description}
              </p>

              <p className="text-sm text-[#FF33FF]">
                {formatDate(dataCampaign?.startTime)} ~{" "}
                {formatDate(dataCampaign?.endTime)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Quizz Button Section */}
      {listDataQuizz.quizzes?.map((item, index) => (
        <div className="flex justify-center items-center p-4">
          <div
            className="flex items-center justify-center w-full rounded-lg p-4 Bg_Card_Comomon "
            style={{ boxShadow: "0px 6px 0px 0px #00c1ec" }}
          >
            <div className="flex-1 flex justify-between items-center">
              <div className="mt-2 flex items-center space-x-2">
                {item?.userStatus ? (
                  <div className="flex flex-col items-center ">
                    <span className="text-[20px]">Completed</span>
                    <span className="text-[16px]">
                      Score: {item?.userStatus?.score}
                    </span>
                  </div>
                ) : (
                  <Button
                    type="primary"
                    className="text-[#000] text-[16px]"
                    style={{
                      backgroundColor: "#00A9FF",
                      borderColor: "#00A9FF",
                    }}
                    onClick={() => handleStartQuizzClick(item)}
                  >
                    Start Quizz
                  </Button>
                )}
              </div>
              <div>{item?.questions?.length} Quiz</div>
            </div>
          </div>
        </div>
      ))}
      <button
        onClick={() => setModalRankingCampaign(true)}
        className="fixed right-4 bottom-[18vh] p-3 rounded-full shadow-lg  transition-colors flex items-center space-x-2 btnCommon"
      >
        {/* <Gift size={24} /> */}
        <div className="text-xl">
          <Crown />
        </div>
      </button>
      {dataCampaign?.creator === getProfileAddress?.address && (
        <button
          onClick={() => {
            if (getProfileAddress?.profile?.quizTickets >= 1) {
              setCreateQuizz(true);
            } else {
              toast.error("Not enough tickets to create a quiz");
            }
          }}
          className="fixed right-4 bottom-[7rem]  p-3 rounded-full shadow-lg  transition-colors flex items-center space-x-2 btnCommon"
        >
          <div className="text-xl">
            <BadgePlus />
          </div>
        </button>
      )}

      <ModalCreateQuizz
        createQuizz={createQuizz}
        setCreateQuizz={setCreateQuizz}
        idCampaign={dataCampaign?.id}
        onOk={() => {
          setCreateQuizz(false);
          const Params = {
            campaignId: dataCampaign.id,
            limit: DEFAULT_PAGE_SIZE,
            page: 1,
          };
          getListDataQuizz(Params);
        }}
      />
      <ModalQuizzCampaign
        showQuizz={showQuizz}
        setShowQuizz={setShowQuizz}
        handleQuizCompletion={handleQuizCompletion}
        dataQuestion={dataQuestion?.questions || []}
        idQuiz={dataQuestion?.id}
        setClickTimeEnd={setClickTimeEnd}
        onOk={() => {
          const Params = {
            campaignId: dataCampaign.id,
            limit: DEFAULT_PAGE_SIZE,
            page: 1,
          };
          getListDataQuizz(Params);
        }}
      />
      <ModalCongratulation
        modalResult={modalResult}
        setModalResult={setModalResult}
        congratulationData={congratulationData}
        timeStartQuiz={clickTimeStart}
        timeEndQuiz={clickTimeEnd}
      />

      <ModalRankCampaign
        modalRankingCampaign={modalRankingCampaign}
        setModalRankingCampaign={setModalRankingCampaign}
        idCampaign={dataCampaign?.id}
      />
    </div>
  );
}

export default CampaignDetail;
