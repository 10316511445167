import queryString from "query-string";
import http from ".";
import { apiScoreCampaign } from "./apiRouter";

const totalScoreQuizzCampaign = (bodyData, body) => {
  const params = queryString.stringify(body);
  return http.post(`${apiScoreCampaign}?${params}`, bodyData);
};

const TotalScoreQuizCampaignServices = {
  totalScoreQuizzCampaign,
};

export default TotalScoreQuizCampaignServices;
