import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { store } from "./store/store";
import "./styles/reset.scss";
import "./styles/customantd.scss";
import { MetaMaskProvider } from "@metamask/sdk-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <MetaMaskProvider
    debug={false}
    sdkOptions={{
      logging: {
        developerMode: false,
      },
      checkInstallationImmediately: false,
      dappMetadata: {
        name: "KAIA LINE APP",
        url: window.location.host,
      },
      i18nOptions: {
        enabled: true,
      },
      i18nInstance: "jp",
    }}
  >
    <Provider store={store}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <App />
      </ConfigProvider>
    </Provider>
  </MetaMaskProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
