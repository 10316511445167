import { Layout, theme } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import "./layout.scss";
import React from "react";
const { Content } = Layout;

const MainLayout = () => {
  //   const {
  //     token: { colorBgContainer },
  //   } = theme.useToken();
  //   const navigate = useNavigate();
  //   const isLogin = localStorage.getItem(ACCESS_TOKEN_KEY);

  //   useEffect(() => {
  //     if (!isLogin) return navigate(ROUTE_PATH.LOGIN);
  //   }, []);
  return (
    <Layout>
      <Layout>
        <Content style={{ height: "100vh" }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
