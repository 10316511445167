import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { CheckWalletContainer } from "./styled";
import CryptoJS from "crypto-js";

const CheckWallet = ({ onToggle, dataAuth }) => {
  const [secretRecoveryPhrase, setSecretRecoveryPhrase] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const decryptMnemonic = async () => {
      const secretKey = "";
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(
          dataAuth?.mnemonic,
          secretKey
        );
        const decryptedMnemonic = decryptedBytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedMnemonic) {
          throw new Error("Failed to decrypt mnemonic");
        }
        setSecretRecoveryPhrase(decryptedMnemonic.split(" "));
      } catch (error) {
        console.error("Error decrypting mnemonic:", error);
      } finally {
        setLoading(false);
      }
    };
    decryptMnemonic();
  }, [dataAuth]);

  const handleCopy = () => {
    const phraseString = secretRecoveryPhrase.join(", ");
    navigator.clipboard.writeText(phraseString);
  };

  const handleSubmit = () => {
    onToggle();
  };

  if (loading) {
    return (
      <CheckWalletContainer>
        <h2 style={{ color: "white" }}>Loading...</h2>
      </CheckWalletContainer>
    );
  }

  return (
    <CheckWalletContainer
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <h2 style={{ marginBottom: "9rem", fontSize: "30px", color: "white" }}>
        Your Secret Recovery Phrase
      </h2>
      <div className="checkGrid">
        {secretRecoveryPhrase.map((text, index) => (
          <div key={index}>
            <div
              style={{
                padding: "5px 10px",
                border: "1px solid black",
                borderRadius: "14px",
                display: "flex",
                justifyContent: "center",
                boxShadow: "0px 8px 25px 0px rgba(255, 121, 102, 0.5)",
                color: "#ffffff",
                backgroundColor: "rgba(255, 121, 102, 0.8)",
              }}
            >
              {text}
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Button
          className="buttonContinue"
          style={{ marginRight: "10px" }}
          onClick={handleCopy}
        >
          Copy
        </Button>
        <Button className="buttonContinue" onClick={handleSubmit}>
          Continue
        </Button>
      </div>
    </CheckWalletContainer>
  );
};

export default CheckWallet;
