import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Trophy, CircleArrowLeft, BadgePlus } from "lucide-react";
import ModalCreateCampaign from "./createCampain";
import CampaignServices from "../../../../services/CampaignServices";
import { ROUTE_PATH } from "../../../../routes/route.constant";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/init.constants";
import { Empty } from "antd";
import { toast } from "sonner";

function ProjectContentDetail() {
  const [activeTab, setActiveTab] = useState("Ongoing");

  const [createCampaign, setCreateCampaign] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const getProfileAddress = JSON.parse(localStorage.getItem("userDataProfile"));

  const [listDataCampaign, setListDataCampaign] = useState([]);
  const [fetchedIds, setFetchedIds] = useState(new Set());
  const [searchParams, setSearchParams] = useState({
    projectId: item.id,
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
    status: activeTab.toUpperCase(),
  });

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observer = React.useRef();
  // Fetch campaigns data
  const getListCampaign = (body) => {
    setLoading(true);
    CampaignServices.getListCampaign(body)
      .then((res) => {
        if (res.data === undefined) {
          localStorage.clear();
          navigate(ROUTE_PATH.WELCOME);
        }
        const newItems = res.data.filter((item) => !fetchedIds.has(item.id)); // Loại bỏ các phần tử đã có
        setListDataCampaign((prev) => [...prev, ...newItems]);
        setFetchedIds(
          (prev) => new Set([...prev, ...newItems.map((item) => item.id)])
        );
        if (newItems.length < DEFAULT_PAGE_SIZE) {
          setHasMore(false);
        }
      })
      .catch((er) => {
        toast.error(er.message);
        // localStorage.clear();
        // navigate(ROUTE_PATH.WELCOME);
        setHasMore(false); // Ngừng tải khi gặp lỗi
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getListCampaign(searchParams);
  }, [searchParams]);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setListDataCampaign([]);
    setFetchedIds(new Set());
    setSearchParams({
      projectId: item.id,
      limit: DEFAULT_PAGE_SIZE,
      page: 1,
      status: tab.toUpperCase(),
    });
    setHasMore(true);
  };

  const lastCampaignRef = useCallback(
    (node) => {
      if (loading || !hasMore) return; // Không làm gì nếu đang tải hoặc không còn dữ liệu
      if (observer.current) observer.current.disconnect(); // Ngắt kết nối observer cũ

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // Khi phần tử cuối cùng được nhìn thấy
          setSearchParams((prev) => ({
            ...prev,
            page: prev.page + 1,
          }));
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const ActivityCard = ({
    icon,
    title,
    points,
    level,
    des,
    badge,
    dataCampaign,
    symbolToken,
  }) => (
    <div
      className="flex items-center justify-between w-full rounded-lg p-4  Bg_Card_Comomon"
      style={{ boxShadow: "0px 6px 0px 0px #00c1ec" }}
      onClick={() => {
        if (activeTab === "Ongoing") {
          navigate(
            ROUTE_PATH.CAMPAIGNCONTENT.replace(":id", dataCampaign.id).replace(
              ":title",
              dataCampaign.name
            ),
            { state: dataCampaign }
          );
        }
      }}
    >
      <div className="flex items-center space-x-4">
        <div className="text-[#000]">{icon}</div>
        <div>
          <h4 className="font-medium text-[18px]">{title}</h4>
          <p className="text-sm text-[#FF33FF] ">{des}</p>
          {/* <p className="text-sm text-[#FF33FF]">{badge ?? 0} Tasks</p> */}
        </div>
      </div>
      <div className="flex items-center space-x-4 flex-col">
        <div className="flex items-center space-x-1">
          {points && (
            <>
              <span className="text-[#000] font-bold mb-2">Reward Pool:</span>
              <span className="text-[#000] font-bold mb-2">
                {points} {symbolToken}
              </span>
            </>
          )}
        </div>

        <div
          className="px-3 py-1 bg-[#00c1ec] text-[#000] rounded-lg text-sm "
          style={{ border: "1px solid #00c1ec" }}
        >
          {level}
        </div>
      </div>
    </div>
  );

  const handleBack = () => {
    navigate(`/homepage`);
  };

  return (
    <div className="min-h-screen text-[#000] bg_common_detail">
      <header className="flex justify-between px-4 py-4  flex-col">
        <div className="mt-[10px]">
          <button
            className="text-[#000] font-bold text-lg flex items-center space-x-2"
            onClick={handleBack}
          >
            <CircleArrowLeft /> <span>Back</span>
          </button>
        </div>
        <h1 className="text-xl font-bold text-center mt-4 mb-2">
          Project Details: {item.name}
        </h1>
      </header>

      {/* Tabs */}
      <div className="flex justify-around  text-white">
        {["Ongoing", "Upcoming", "Ended"].map((tab) => (
          <button
            key={tab}
            className={`px-4 py-2 text-[16px] font-bold text-[#000] ${
              activeTab === tab
                ? "bg-[#00cee4] rounded-[20px] mt-1 mb-1"
                : "bg-transparent"
            }`}
            onClick={() => handleTabChange(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      {/* Content */}
      <div
        className="space-y-4 p-4"
        style={{ height: "calc(100vh - 25em)", overflowY: "auto" }}
      >
        {listDataCampaign
          ?.filter((item) => {
            if (activeTab === "Ongoing") return item.status === "ONGOING";
            if (activeTab === "Upcoming") return item.status === "UPCOMING";
            if (activeTab === "Ended") return item.status === "ENDED";
            return false;
          })
          ?.map((filteredItem, index) => (
            <div
              key={filteredItem.id}
              ref={
                index === listDataCampaign.length - 1 ? lastCampaignRef : null
              }
            >
              <ActivityCard
                icon={<Trophy size={24} />}
                title={filteredItem.name}
                points={filteredItem.totalReward}
                symbolToken={filteredItem.symbolToken}
                level={filteredItem.status}
                des={filteredItem.description}
                activeTab={activeTab}
                dataCampaign={filteredItem}
              />
            </div>
          ))}
        {/* Fallback message */}
        {!listDataCampaign.filter((item) => {
          if (activeTab === "Ongoing") return item.status === "ONGOING";
          if (activeTab === "Upcoming") return item.status === "UPCOMING";
          if (activeTab === "Ended") return item.status === "ENDED";
          return false;
        }).length && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ margin: "20px" }}
            description={<span style={{ color: "#000" }}>No data</span>}
          />
        )}
      </div>
      {item?.owner === getProfileAddress?.address && (
        <button
          onClick={() => setCreateCampaign(true)}
          className="fixed right-4 bottom-[7rem]  p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors flex items-center space-x-2 btnCommon"
        >
          <BadgePlus />
        </button>
      )}

      <ModalCreateCampaign
        idProject={item.id}
        onOk={() => {
          setCreateCampaign(false);
          setListDataCampaign([]);
          setFetchedIds(new Set());
          setSearchParams({
            projectId: item.id,
            limit: DEFAULT_PAGE_SIZE,
            page: 1,
            status: activeTab.toUpperCase(),
          });
          setHasMore(true);
        }}
        createCampaign={createCampaign}
        setCreateCampaign={setCreateCampaign}
      />
    </div>
  );
}

export default ProjectContentDetail;
